<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Registrar proveedor</div>
      </v-col>
    </v-row>

    <!-- <v-toolbar flat color="light-green accent-4">
        <v-btn @click="$router.push('/Proveedores')" text>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>
          <div class="headline">Registrar  proveedor</div>
        </v-toolbar-title>
    </v-toolbar>-->
    <v-row justify="center">
      <v-col cols="12">
        <form-proveedor
          @close-dialog="$emit('close-dialog')"
          :proveedor="proveedor"
          :provincias="provincias"
          :isUpdate="false"
          @processProveedor="registrarProveedor"
          :planDeCuentas="planDeCuentas"
        ></form-proveedor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormProveedor from "@/components/proveedor/FormProveedor";
import { mapActions, mapState } from "vuex";
export default {
  name: "ProveedorCreate",
  components: {
    FormProveedor,
  },
  data() {
    return {
      provincias: [],
      proveedor: {
        nombre: "",
        telefono: "",
        email: "",
        direccion: "",
        localidad: { id: null },
        tipo_documento: 1,
        documento: null,
        categorias: [],
        condicionIva: null,
        cuenta_corriente: null,
        cuenta_contado: null,
        cuenta_otros: null,
        rubro_compra: null,
      },
    };
  },
  computed: {
    ...mapState("contabilidad", ["planDeCuentas"]),
  },
  methods: {
    ...mapActions("proveedor", ["registrar_proveedor"]),
    ...mapActions("contabilidad", ["fetchPlanDeCuentas"]),

    async fetchCuentas() {
      try {
        await this.fetchPlanDeCuentas({ empresaUuid:null, isActive: true });
      } catch (error) {
        console.error("Error al obtener el plan de cuentas:", error);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: "Error al obtener el plan de cuentas",
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },

    registrarProveedor(data) {
      this.registrar_proveedor(data).then(() => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Proveedor registrado",
          icon: "mdi-bell-ring",
          timeout: 3000,
        });
        this.$emit("close-dialog");
      });
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  mounted() {
    this.fetchProvincias();
  },
  created() {
    this.fetchCuentas();
  },
};
</script>

<style lang="scss" scoped>
</style>
